import Navbar from "../../component/Navbar";
import { Notamlist } from "../../component/items/NotamList";
import { Table } from "../../component/items/Table";

export default function ListingData() {
  return (
    <>
      <Navbar />
      <div className="mt-20">
        {/* <Table /> */}
        <Notamlist/>
      </div>
    </>
  );
}
