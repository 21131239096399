import { LogoIonic, Rose } from "react-ionicons";
import { Link, Navigate, Route, useNavigate } from "react-router-dom";

export default function ChatBox() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="container p-1 m-2 flex rounded  border-2 cursor-pointer hover:bg-gray-100"
        onClick={() => {
          navigate("/chat-inner");
        }}
      >
        <div className="w-20">
          <span className=" w-10 ">
            {<LogoIonic style={{ height: "100%", width: "100%" }} />}
          </span>
        </div>
        <div className=" w-full p-2 flex flex-col">
          <div className="text-xl">Group Name</div>
          <div className=" w-full text-end">
            <span className="text-sm">Yesterday</span>
          </div>
        </div>
      </div>

      <div className="container p-1 m-2 flex rounded border-2 cursor-pointer hover:bg-gray-100 bg-indigo-50">
        <div className="w-20">
          <span className=" w-10 ">
            {<LogoIonic style={{ height: "100%", width: "100%" }} />}
          </span>
        </div>
        <div className=" w-full p-2 flex flex-col ">
          <div className="text-xl">Group Name</div>
          <div className=" w-full text-end">
            <span className="text-sm">Yesterday</span>
          </div>
        </div>
        <div className="w-5 bg-green-300 rounded-xl border-2 text-center right-7 z-10 absolute">
          <span className="">2</span>
        </div>
      </div>
    </>
  );
}
