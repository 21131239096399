import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  IconButton,
  Tooltip,
  Tabs,
  TabsHeader,
  Tab,
} from "@material-tailwind/react";
import React, { FC, useEffect, useState } from "react";
import { dataGet, dataPost } from "../../network";
import { aerodromeDeleteData, aerodromeUrl, emails } from "../../network/const";
import { AuthData } from "../../context/AuthWrapper";
import { Dialog } from "./Dialog";
import { dateFormat, generateUniqueId } from "../../include/Common";
import axios, { CancelToken } from "axios";
import { useNavigate } from "react-router-dom";
import { AerodomeModel } from "../../network/dataType";
import { MessageDialog } from "./MessageDialog";
import ReadMore from "./ReadMore";
type TableProps = {
  TABLE_HEAD?: string[];
  Api?: string;
};
const TABS = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
  {
    label: "500",
    value: 500,
  },
  {
    label: "All",
    value: -1,
  },
];

const TABLE_HEAD = [
  { name: "Id", class: " w-50 " },
  { name: "Name", class: " w-50" },
  { name: "Aerodrome", class: "" },
  { name: "Description", class: "w-72" },
  { name: "Status", class: "" },
  { name: "Details", class: "" },
];

export const AerodromeTable: React.FC<TableProps> = ({}) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLinks, setPageLinks] = useState<number[]>([]);
  const [view, setView] = useState(false);
  const [alertDialogStatus, setAlertDialogSlatus] = useState(false);
  type dialog = {
    title: string;
    description: any;
    btnText: string;
    data: any;
  };
  type dialogAlert = {
    title: string;
    description: any;
    btnText: string;
  };
  const [dialogData, setDialogData] = useState<dialog>({
    description: "",
    title: "",
    btnText: "",
    data: "",
  });
  const [alertDialogData, setAlertDialogData] = useState<dialogAlert>({
    description: "",
    title: "",
    btnText: "",
  });
  const [TABLE_ROWS, setTABLEROWS] = useState<AerodomeModel[]>([]);
  const authData = AuthData();
  // const TABLE_HEAD = ;
  const fetchData = async (source: CancelToken) => {
    const aerodromeData = await dataGet(
      aerodromeUrl,
      {
        page: page,
        perPage: perPage,
      },
      source
    );
    if (aerodromeData.status) {
      if (aerodromeData.data.rows) {
        setTABLEROWS(aerodromeData.data.rows);
        setTotalPages(aerodromeData.data.totalPages);
        pageLinksFun(aerodromeData.data.totalPages);
      } else {
        setTotalPages(0);
      }
    } else {
      authData?.setError({
        error: true,
        errorDescription: aerodromeData.message,
        errorTitle: aerodromeData.title,
        errorType: "error",
        hide: aerodromeData.hide,
      });
    }
  };

  const deleteFunction = async (row: string) => {
    const url = aerodromeDeleteData + "/" + row;
    const data = await dataPost(url, {});
    if (data.status) {
      setRefresh(Math.random().toString());
      setAlertDialogSlatus(true);

      setAlertDialogData({
        title: data.title,
        description: data.message,
        btnText: "Ok",
      });
    } else {
      setAlertDialogSlatus(true);
      setAlertDialogData({
        title: data.title,
        description: data.message,
        btnText: "Ok",
      });
    }
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    fetchData(source.token);

    return () => {
      source.cancel("Component is unmounted"); // Cancel the request when the component is unmounted
    };
  }, [page, perPage, refresh]);

  const pageLinksFun = (totalPage: number, showingLinks: number = 4) => {
    let i = 1;
    const limit: number = showingLinks / 2;
    const minLimit = page - limit <= 0 ? 1 : page - limit;
    const balance = minLimit < limit ? limit + minLimit : 0;
    let maxLimit =
      minLimit + limit + balance >= totalPage
        ? totalPage
        : minLimit + limit + balance;
    console.log(minLimit, maxLimit);

    let pages = [];
    for (i = minLimit; i <= maxLimit; i++) pages.push(i);
    setPageLinks(pages);
  };

  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none h-">
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Tabs value={perPage} className="w-full md:w-max">
                <TabsHeader>
                  {TABS.map(({ label, value }) => (
                    <Tab
                      key={value}
                      value={value}
                      onClick={(e) => {
                        setPage(1);
                        setPerPage(value);
                      }}
                    >
                      {label}
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
            </div>
            <div className="flex w-full shrink-0 gap-2 md:w-max">
              <Button
                color="green"
                variant="gradient"
                onClick={() => {
                  navigate("/aerodrome-manage");
                }}
              >
                <span>Add Aerodrome</span>
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto text-left break-all  whitespace-break-spaces">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head.name}
                    className={`border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 ${head.class}`}
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70 "
                    >
                      {head.name}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {TABLE_ROWS.length ? (
                TABLE_ROWS.map((row, index) => {
                  const { id, name, description, aerodrome, status } = row;
                  const isLast = index === TABLE_ROWS.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";

                  return (
                    <tr key={id}>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold"
                          >
                            {id}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.aerodrome}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <ReadMore text={row.description} maxLength={45} />
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.status}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Tooltip content="Edit">
                          <IconButton
                            variant="text"
                            className="border bg-cyan-400  m-1"
                            onClick={() => {
                              navigate("/aerodrome-manage", {
                                state: row,
                              });
                            }}
                          >
                            <PencilIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip content="Delete">
                          <IconButton
                            variant="text"
                            className="border bg-red-200  "
                            onClick={() => {
                              setView(true);
                              setDialogData({
                                title: "Delete",
                                btnText: "Yes",
                                description: `Do you want to delete ${row.name}`,
                                data: row.id,
                              });
                            }}
                          >
                            <TrashIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className=" text-center" colSpan={6}>
                    Empty Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Button
            variant="outlined"
            size="sm"
            onClick={() => setPage((prev) => (prev == 1 ? 1 : prev - 1))}
          >
            Previous
          </Button>
          <div className="flex items-center gap-2">
            {pageLinks.map((e) => {
              return (
                <IconButton
                  key={generateUniqueId()}
                  variant="outlined"
                  size="sm"
                  className={`${page == e ? "bg-blue-gray-100" : ""}`}
                  onClick={() => setPage(e)}
                >
                  {e}
                </IconButton>
              );
            })}
          </div>
          <Button
            variant="outlined"
            size="sm"
            onClick={() =>
              setPage((prev) => (prev == totalPages ? totalPages : prev + 1))
            }
          >
            Next
          </Button>
        </CardFooter>
      </Card>
      <MessageDialog
        open={view}
        handleOpen={() => {
          setView(!view);
          deleteFunction(dialogData.data);
        }}
        handleClose={() => {
          setView(!view);
        }}
        description={dialogData.description}
        title={dialogData.title}
        succesBtn={dialogData.btnText}
        succesBtnClass={""}
        cancelBtn={"Cancel"}
        cancelBtnClass={"bg-red-200"}
        size="sm"
      />
      <Dialog
        open={alertDialogStatus}
        handleOpen={() => {
          setAlertDialogSlatus(!alertDialogStatus);
        }}
        title={alertDialogData.title}
        description={alertDialogData.description}
        buttonText={"Ok"}
      />
    </>
  );
};
