import { Route, Navigate, useLocation } from "react-router-dom";
import { AuthData } from "../context/AuthWrapper";
import { saveLastUrlToLocalStorage } from "../include/token";

interface ProtectedRouteProps {
  //   isAuthenticated: boolean;
  //   authenticationPath: string;
  Component: React.FC;
  //   path: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  //   authenticationPath,
  Component,
  ...rest
}) => {
  const authData = AuthData();
  const location = useLocation();
  //   return (
  //     <Route
  //       {...rest}
  //       path={authenticationPath}
  //       Component={
  //         Component
  //         // <Component />
  //         // authData?.user.isAuthenticated ? (
  //         // ) : (
  //         //   <Navigate replace to="/" />
  //         // )
  //       }
  //     />
  //   );
  if (authData?.user.isAuthenticated) {
    return <Component />;
  } else {
    saveLastUrlToLocalStorage(location.pathname);
    return <Navigate replace to={"/login"} />;
  }
};
