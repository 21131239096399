import Navbar from "../../../component/Navbar";
import { AppConstTable } from "../../../component/items/AppConstTable";

export default function AppConst() {
  return (
    <>
      <Navbar />
      <div className="mt-20">
        <AppConstTable />
      </div>
    </>
  );
}
