import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {
    ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import {
    Button,
} from "@material-tailwind/react";

const ExportToExcel = ({ data, fileName, sheetName }) => {

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const file = new Blob([excelBuffer], { type: fileType });
        saveAs(file, `${fileName}.xlsx`);
    };

    return (

        <Button disabled={data.length ? false : true} className="flex items-center gap-3" size="sm" onClick={exportToExcel}>
            <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" /> Export
        </Button>
    );
};

export default ExportToExcel;