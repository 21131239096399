import React, { ChangeEvent, MouseEventHandler, ReactNode } from "react";

import {
  Button,
  Dialog as MUIDialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
type dialog = {
  open: boolean;
  handleOpen: MouseEventHandler;
  title: string;
  description: any;
  buttonText: string;
  size?: "md" | "lg" | "sm" | "xl" | "xxl";
};
export const Dialog = (props: dialog) => {
  return (
    <>
      <MUIDialog
        open={props.open}
        handler={props.handleOpen}
        size={props.size ? props.size : "md"}
      >
        <DialogHeader>{props.title}</DialogHeader>
        <DialogBody
          divider
          className={` text-black ${
            props.size == "xl" ? "h-[calc(100vh-100px)] overflow-scroll" : ""
          }`}
        >
          {props.description}
        </DialogBody>
        <DialogFooter>
          <Button
            className=""
            variant="gradient"
            color="green"
            onClick={props.handleOpen}
          >
            <span>{props.buttonText}</span>
          </Button>
        </DialogFooter>
      </MUIDialog>
    </>
  );
};
