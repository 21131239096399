import React, { useState } from "react";
import { LogoIonic, MenuSharp, Close, Person } from "react-ionicons";
import { AuthData } from "../context/AuthWrapper";
import { Link } from "react-router-dom";
import { generateUniqueId, toSnakeCase } from "../include/Common";
import { Button, Typography } from "@material-tailwind/react";
let links = [
  { name: "Home", link: "/", private: false },
  { name: "About Us", link: "/about-us", private: false },
  { name: "Login", link: "/login", private: false },
  { name: "Load Data", link: "/load-data", private: true },
  { name: "Notam List", link: "/listing", private: true },
  { name: "Users", link: "/user", private: true },
  // { name: "Aerodrome", link: "/aerodrome", private: true },
  // { name: "Logout", link: "/", private: true },
];
function Navbar({ homePage = false }) {
  let [open, setOpen] = useState(false);
  let [dropDown, setDropDown] = useState(false);
  const authData = AuthData();
  return (
    <>
      <div className="shadow-md w-full fixed left-0 top-0 bg-white z-[1001]">
        {homePage && (
          <div className="bg-red-600 text-white font-bold text-center w-full">
            The website is still under development and some NOTAM information
            might not be accurate or available.
          </div>
        )}
        <div className=" md:flex items-center justify-between bg-white py-4 md:px-10 ">
          <div className=" font-bold flex  items-center text-2xl cursor-pointer font-[Poppins] text-gray-800">
            <span className=" text-3xl text-indigo-600 mr-1 ">
              {<LogoIonic />}
            </span>
            Notam
          </div>
          <div
            className=" text-3xl absolute right-8 top-6 cursor-pointer  md:hidden"
            onClick={() => setOpen(!open)}
          >
            {open ? <Close /> : <MenuSharp />}
          </div>
          <ul
            className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static  bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
              open
                ? "top-19 opacity-100"
                : "top-[-490px] opacity-0 md:opacity-100"
            }`}
          >
            {links.map((q) => {
              const isAuth = authData?.user.isAuthenticated;
              if (isAuth) {
                if (q.name == "Login")
                  return <span key={generateUniqueId()}></span>;
                // else if (q.name == "Logout") {
                //   return (
                //     <li
                //       key={toSnakeCase(q.name)}
                //       className="md:ml-8 text-xl md:my-0 my-7 "
                //     >
                //       <a
                //         className="text-gray-800 hover:text-gray-400 duration-500"
                //         href={"#"}
                //         onClick={() => {
                //           authData.logout();
                //         }}
                //       >
                //         {q.name}
                //       </a>
                //     </li>
                //   );
                // }
              } else {
                if (q.private) {
                  return <span key={generateUniqueId()}></span>;
                }
              }
              return (
                <li
                  key={toSnakeCase(q.name)}
                  className="md:ml-8 text-xl md:my-0 my-7 "
                >
                  <Link
                    className="text-gray-800 hover:text-gray-400 duration-500"
                    to={`${q.link}`}
                  >
                    {q.name}
                  </Link>
                </li>
              );
            })}
            {authData?.user.isAuthenticated && (
              <div className="relative inline-block text-left pl-3">
                <div>
                  <Button
                    variant="outlined"
                    className="rounded-full"
                    onClick={() => setDropDown(!dropDown)}
                    size="sm"
                    onBlur={() => {
                      setTimeout(() => {
                        setDropDown(false);
                      }, 1000);
                    }}
                  >
                    <Person></Person>
                  </Button>
                </div>

                {dropDown && (
                  <div
                    className={`origin-top-right absolute ${
                      open ? "left-0" : "right-0"
                    } mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
                  >
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <Typography className="text-center border-b-2">
                        {authData.user.name}
                      </Typography>
                      {/* Dropdown items */}
                      <Link
                        to={"/aerodrome"}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Aerodrome
                      </Link>
                      <Link
                        to={"/app-const"}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Master Data
                      </Link>
                      <a
                        href="#"
                        onClick={() => {
                          authData.logout();
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
          </ul>
        </div>
      </div>
      <div
        className={`${
          open
            ? authData?.user.isAuthenticated
              ? "mb-[300px]"
              : "mb-[200px]"
            : ""
        } `}
      ></div>
    </>
  );
}

export default Navbar;
