import React from 'react'
import loading from "../../assets/loading.gif"

export default function LoadingComponent() {
    return (
        <div className='loading-container'>
            <img src={loading}></img>
        </div>
    )
}
