import Navbar from "../../component/Navbar";
import ChatBox from "../../component/chat/ChatBox";

export default function ChatHome() {
  return (
    <>
      <Navbar />
      <div className=" pt-20">
        <ChatBox />
      </div>
    </>
  );
}
