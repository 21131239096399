import React from "react";
import Navbar from "../../component/Navbar";

function AboutUs() {
  return (
    <>
      <div className="pt-28 pb-8 flex justify-center items-center flex flex-col gap-4">
        <Navbar homePage={true} />
        <div className="pt-8 mx-auto p-12">
          <h1 className="text-3xl font-bold text-gray-900 mb-6 uppercase">
            About Us
          </h1>
          <h3 className="text-lg font-bold mb-4">
            Notification: About NOTAM Information on This Website
          </h3>
          <p className="text-base text-gray-900 mb-6">
            Greetings
            <br />
            This website gathers Notice to Airmen (NOTAM) information from
            multiple open-source websites and consolidates it here for display
            purposes only. The intention is to provide users with convenient
            access to NOTAM data in one centralized location.
          </p>
          <h3 className="text-lg font-bold mb-4">Important Points to Note:</h3>
          <ul className="list-disc pl-4 text-base text-gray-900 mb-6">
            <li className="mb-2">
              <span className="font-bold">Source: </span>The NOTAM information
              displayed on this website is sourced from various open-source
              platforms dedicated to aviation information.
            </li>
            <li className="mb-2">
              <span className="font-bold">Display Purpose Only: </span>Our goal
              is to offer users a user-friendly interface to access NOTAM data.
              We do not alter or modify the content; instead, we aim to present
              it in a cohesive and easily accessible format.
            </li>
            <li className="mb-2">
              <span className="font-bold">Accuracy: </span>While we strive to
              provide accurate and up-to-date information, users should verify
              critical details directly with the relevant aviation authorities.
              The primary responsibility for NOTAM accuracy lies with the
              original NOTAM office.
            </li>
            <li className="mb-2">
              <span className="font-bold">Coverage : </span>At this stage only
              UAE NOTAM are updated Promptly , other countries are updated once
              a day.
            </li>
          </ul>
          <p className="text-base text-gray-900 mb-6">
            <span className="font-bold">Disclaimer: </span>This website is not
            affiliated with any official aviation authority. It is crucial for
            users to cross-reference the information displayed here with
            official channels for flight planning and aviation-related
            activities.
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
