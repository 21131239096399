import React, { createContext, useContext, useEffect, useState } from "react";
// import { RenderHeader } from "../components/structure/Header";
// import { RenderMenu, RenderRoutes } from "../components/structure/RenderNavigation";

type errorType = {
  error: boolean;
  errorType: "success" | "warning" | "error" | null;
  errorTitle: string;
  errorDescription: string;
  hide?: boolean;
  code?: number;
};
type AuthContextType = {
  user: any;
  error: errorType;
  login: (data: any) => void;
  logout: () => void;
  setError: ({}: errorType) => void;
};

type AuthWrapperProps = {
  children: React.ReactNode;
};
const AuthContext = createContext<AuthContextType | null>(null);
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [user, setUser] = useState({
    name: "",
    isAuthenticated: false,
    email: "",
    token: "",
  });
  const [error, setError] = useState<errorType>({
    error: false,
    errorType: null,
    errorDescription: "",
    errorTitle: "",
  });

  useEffect(() => {
    // Check local storage for user data on component mount
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const { username, email, token } = JSON.parse(storedUser);
      setUser({
        name: username,
        email: email,
        isAuthenticated: true,
        token: token,
      });
    }
  }, []);

  useEffect(() => {
    console.log("ccccccccooooooode", error.code);
    if (error.code == 401) {
      logout();
    }
  }, [error.code]);

  const login = (data: any) => {
    const { username, email, token } = data;
    localStorage.setItem("user", JSON.stringify(data));
    setUser({
      name: username,
      email: email,
      isAuthenticated: true,
      token: token,
    });
  };
  const logout = () => {
    setUser({ ...user, isAuthenticated: false, token: "" });
    localStorage.removeItem("user");
  };

  const setErrorData = ({
    error,
    errorDescription,
    errorTitle,
    errorType,
    hide = false,
  }: errorType) => {
    setError({ error, errorDescription, errorTitle, errorType, hide });
  };
  const authContextValue: AuthContextType = {
    user,
    login,
    logout,
    error,
    setError,
  };
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
