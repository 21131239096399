import {
  Card,
  Input,
  Button,
  Typography,
  Textarea,
  Select,
  Option,
} from "@material-tailwind/react";
import Navbar from "../../../component/Navbar";
import { MouseEvent, useState } from "react";
import { dataPost } from "../../../network";
import { aerodromePostData, postData } from "../../../network/const";
import { Dialog } from "../../../component/items/Dialog";
import { dialogDataTypes } from "../../../component/items/itemDataType";
import { useLocation, useNavigate } from "react-router-dom";
import { AerodomeModel } from "../../../network/dataType";

export default function AerodromeAdd() {
  const navigate = useNavigate();
  const dataState: {
    state: AerodomeModel | null;
  } = useLocation();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    btnText: "",
    description: "",
    status: false,
  });
  const [formState, setFormState] = useState({
    name: dataState.state?.name,
    description: dataState.state?.description,
    status: dataState.state?.status ?? "In-Active",
    aerodrome: dataState.state?.aerodrome,
  });
  const handleChange = (e: any) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
    console.log(formState);
  };
  const initDialog = (data: dialogDataTypes) => {
    setOpen(true);
    setDialogData({
      title: data.title,
      btnText: data.btnText,
      description: data.description,
      status: data.status ?? false,
    });
  };

  const submitData = async () => {
    const url =
      aerodromePostData + (dataState.state?.id ? `/${dataState.state.id}` : "");
    const data = await dataPost(url, formState);
    console.log(data);

    if (data.status) {
      initDialog({
        description: data.message,
        title: data.title,
        btnText: "OK",
        status: data.status,
      });
      // setFormState({ email: "notam@info.com", identification: "", data: "" });
    } else {
      initDialog({
        description: data.message,
        title: data.title,
        btnText: "OK",
      });
    }
  };
  const action = dataState.state?.id ? "Edit" : "Add";
  return (
    <>
      <Navbar />
      <div className="mt-20">
        <Card color="transparent" className=" items-center" shadow={false}>
          <Typography variant="h4" color="blue-gray">
            Aerodrome {action}
          </Typography>
          <form className="mt-8 mb-2 p-5  max-w-screen-lg w-full">
            <div className="mb-4 flex flex-col gap-6">
              <Input
                size="lg"
                label="Name"
                crossOrigin={undefined}
                id="name"
                onChange={handleChange}
                value={formState.name}
              />
              <Input
                size="lg"
                label="Aerodrome"
                crossOrigin={undefined}
                id="aerodrome"
                onChange={handleChange}
                value={formState.aerodrome}
              />
              <Select
                id="status"
                label="Select Version"
                value={formState.status}
                onChange={(value) => {
                  handleChange({
                    target: {
                      id: "status",
                      value,
                    },
                  });
                }}
              >
                <Option value="In-Active">In-Active</Option>
                <Option value="Active">Active</Option>
              </Select>
              <Textarea
                label="Description"
                rows={8}
                id="description"
                onChange={handleChange}
                value={formState.description}
              />
            </div>
            <div className="flex space-x-2 ">
              <Button className="mt-6" fullWidth onClick={() => navigate(-1)}>
                Cancel
              </Button>

              <Button
                className="mt-6"
                color="green"
                fullWidth
                onClick={submitData}
              >
                {action}
              </Button>
            </div>
          </form>
        </Card>

        <Dialog
          open={open}
          handleOpen={() => {
            setOpen(!open);
            if (dialogData.status) navigate(-1);
          }}
          title={dialogData.title}
          description={dialogData.description}
          buttonText={dialogData.btnText}
        />
      </div>
    </>
  );
}
