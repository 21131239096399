export const dateFormat = (data: string | null, onlyDate: boolean = false) => {
  if (data == null) {
    return "";
  }
  const date = new Date(data);
  const convertedDate = new Date(
    date.toLocaleString("en-US", { timeZone: "Asia/Dubai" })
  );

  if (onlyDate) {
    return date.toLocaleDateString();
  }

  // Format the date
  const formattedDate = convertedDate
    .toISOString()
    .replace("T", " ")
    .replace(/\.\d{3}Z/, "");

  return formattedDate;
};

export const toSnakeCase = (text: string) => {
  return text
    .replace(/\s+/g, "_") // Replace whitespace with underscores
    .replace(/([a-z])([A-Z])/g, "$1_$2") // Convert camelCase to snake_case
    .toLowerCase(); // Convert all letters to lowercase
};

export const generateUniqueId = (str: string = "") => {
  return (
    toSnakeCase(str) + `id_${Date.now()}_${Math.floor(Math.random() * 1000)}`
  );
};

export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const snakeCaseToWordCase = (str: string) => {
  return str.toUpperCase().replace(/_([a-z])/g, function (match, letter) {
    return " " + letter.toUpperCase();
  });
};

export const getNumberWithSuffix = (day: number | string) => {
  day = parseInt(day.toString()) ?? 0;
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};
