// @flow
import * as React from "react";
import { generateUniqueId } from "../include/Common";
type Props = {
  data: string[];
  removeFunction: Function;
};
export const InputTags = ({ removeFunction, data }: Props) => {
  return (
    <div className=" flex flex-wrap rounded-lg bg-primary dark:bg-gray-400">
      {data?.map((e) => (
        <span
          key={generateUniqueId()}
          className="flex flex-wrap pl-4 pr-2 py-2 m-1 justify-between items-center text-sm font-medium rounded-xl cursor-pointer bg-gray-500 text-gray-200 hover:bg-green-600 hover:text-gray-100 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 dark:hover:text-gray-100"
        >
          {e}
          <svg
            onClick={() => {
              removeFunction(e);
            }}
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 ml-3 hover:text-gray-300"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      ))}
    </div>
  );
};
