// @flow
import * as React from "react";
import { Alert, Button, Typography } from "@material-tailwind/react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { AuthData } from "../context/AuthWrapper";

type Props = {};
type PropsIcon = {
  type: "success" | "error" | "warning" | null;
};

export const _Icon = (props: PropsIcon) => {
  if (props.type == "error") {
    return (
      <>
        <ExclamationTriangleIcon className="h-6 w-6 text-red-300" />
      </>
    );
  } else if (props.type == "success") {
    return (
      <>
        <CheckCircleIcon className="h-6 w-6 text-blue-500" />
      </>
    );
  } else if (props.type == "warning") {
    return (
      <>
        <InformationCircleIcon className="h-6 w-6 text-yellow-300" />
      </>
    );
  }
  return <div></div>;
};

export const AlertComponent = (props: Props) => {
  const authData = AuthData();

  console.log(authData)

  return (
    <>
      {authData?.error.error ? (
        <Alert
          open={authData?.error.error && !authData.error.hide}
          className="max-w-screen-md left-0 bottom-0 z-[1000] absolute
        "
          icon={<_Icon type={authData?.error.errorType} />}
          onClose={() => {
            authData?.setError({
              error: false,
              errorDescription: "",
              errorTitle: "",
              errorType: "error",
            });
          }}
        >
          <Typography variant="h5" color="white">
            {authData?.error.errorTitle}
          </Typography>
          <Typography color="white" className="mt-2 font-normal">
            {authData?.error.errorDescription}
          </Typography>
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
};
