import Navbar from "../../../component/Navbar";
import { UsersTable } from "../../../component/items/UsersTable";

export default function Users() {
  return (
    <>
      <Navbar />
      <div className="mt-20">
        <UsersTable />
      </div>
    </>
  );
}
