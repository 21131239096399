import { useEffect, useState } from "react";
import Header from "../../component/items/Header";
import Input from "../../component/items/Input";
import Button from "../../component/items/Button";
import { dataPost } from "../../network";
import { Dialog } from "../../component/items/Dialog";
import { dialogDataTypes } from "../../component/items/itemDataType";
import { getLastUrlFromLocalStorage } from "../../include/token";
import { loginUrl } from "../../network/const";
import { AuthData } from "../../context/AuthWrapper";
import { useLocation, useNavigate } from "react-router-dom";

const loginFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
];
const fields = loginFields;
let fieldsState: any = {};
// fields.forEach(
//   (field) =>
//     (fieldsState[field.id] =
//       field.id == "email" ? "ambalansajeer@gmail.com" : "123123")
// );
function Login() {
  const [loginState, setLoginState] = useState(fieldsState);
  const [dialog, setDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    description: "",
    btnText: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  // const history = useHist();

  const authData = AuthData();
  useEffect(() => {
    if (authData?.user.isAuthenticated) {
      console.log(location.pathname, "user availabel ", authData?.user);
      const lastUrl = getLastUrlFromLocalStorage();
      navigate(lastUrl ?? "/listing");
    } else {
      console.log("auth disabled");
    }
  }, [authData?.user.isAuthenticated]);
  const handleChange = (e: any) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
    console.log(loginState);
  };

  const dialogInit = ({ title, btnText, description }: dialogDataTypes) => {
    setDialogData({
      title,
      btnText,
      description,
    });
  };
  const submit = async (e: Event) => {
    if (loginState.email && loginState.password) {
      e.preventDefault();
      console.log(loginState);
      const data = await dataPost(loginUrl, loginState);
      if (data.status === false) {
        setDialog(true);
        dialogInit({
          title: "Error",
          description: data.message,
          btnText: "OK",
        });
      } else {
        authData?.login(data.data);
      }
    } else {
      setDialog(true);
      setDialogData({
        title: "Validation Error",
        description: "Please fill the Form",
        btnText: "Ok",
      });
    }
  };
  return (
    <div className=" justify-center items-center flex h-screen">
      <div className="max-w-md w-full space-y-8">
        <Header
          heading="Login to your account"
          paragraph="Don't have an account yet? "
          linkName="Signup"
          linkUrl="/listing"
        />
        <form className="mt-8 space-y-6" method="post">
          <div className="-space-y-px">
            {fields.map((field) => (
              <Input
                key={field.id}
                handleChange={handleChange}
                value={loginState[field.id]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                customClass={""}
              />
            ))}
            <div className=" mt-11">
              <Button action={"button"} handleSubmit={submit} text={"Login"} />
            </div>
          </div>
        </form>
        <Dialog
          open={dialog}
          handleOpen={() => setDialog(!dialog)}
          description={dialogData.description}
          title={dialogData.title}
          buttonText={dialogData.btnText}
        />
      </div>
    </div>
  );
}

export default Login;
