export const saveTokenToLocalStorage = (token: string) => {
  localStorage.setItem("token", token);
};

// Function to get access token from local storage
export const getAccessTokenFromLocalStorage = () => {
  const data = localStorage.getItem("user");
  if (data) {
    const json = JSON.parse(data);
    return json?.token;
  }
};
export const saveLastUrlToLocalStorage = (value: string) => {
  localStorage.setItem("lastUrl", value);
};

// Function to get access token from local storage
export const getLastUrlFromLocalStorage = () => {
  return localStorage.getItem("lastUrl");
};
