import React from "react";
import Input from "../items/Input";
import { LogoIonic, SendSharp } from "react-ionicons";

export default function SingleChat() {
  return (
    <>
      <div>
        <div className="flex relative m-5 rounded-xl mr-auto p-3 border-2 w-10/12">
          <div className="w-full flex  flex-wrap">
            <span className=" h-12 w-12">
              {<LogoIonic style={{ height: "100%", width: "100%" }} />}
            </span>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
              similique praesentium laudantium temporibus dolor ea quam
              repudiandae. Laborum eveniet, suscipit odit nobis consequatur, a
              quisquam corporis, accusamus quaerat amet harum.
            </span>
          </div>
          <div className="z-10 absolute right-0 bottom-0 bg-slate-300">
            12:30
          </div>
        </div>
        <div className="flex justify-end right-0 relative m-5 ml-auto rounded-xl p-3 border-2 w-10/12">
          <div className="w-full flex flex-wrap">
            <span className=" h-12 w-12">
              {<LogoIonic style={{ height: "100%", width: "100%" }} />}
            </span>
            <span className="w-full">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
              similique praesentium laudantium temporibus dolor ea quam
              repudiandae. Laborum eveniet, suscipit odit nobis consequatur, a
              quisquam corporis, accusamus quaerat amet harum.
            </span>
          </div>
          <div className="z-10 absolute right-0 bottom-0 bg-slate-300">
            12:30
          </div>
        </div>
      </div>
    </>
  );
}
