import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Main from "./screen/main";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screen/auth";
import ListingData from "./screen/admin/ListingData";
import ChatHome from "./screen/chatHome/ChatHome";
import ChatInner from "./screen/chatHome/ChatInner";
import { LoadData } from "./screen/admin/LoadData";
import { AuthWrapper } from "./context/AuthWrapper";
import { AlertComponent } from "./component/AlertComponent";
import { ProtectedRoute } from "./route/ProtectedRoute";
import Aerodrome from "./screen/admin/Aerodrome";
import AerodromeAdd from "./screen/admin/Aerodrome/AerodromeAdd";
import Users from "./screen/admin/Users";
import UserAdd from "./screen/admin/Users/UserAdd";
import { Index } from "./screen/admin/Gmail/Index";
import AppConst from "./screen/admin/AppConst";
import AppConstAdd from "./screen/admin/AppConst/AppConstAdd";
import AboutUs from "./screen/about/AboutUs";

function App() {
  return (
    <AuthWrapper>
      <Router>
        <Routes>
          <Route path="/" Component={Main} />
          {/* <ProtectedRoute
            Component={ListingData}
            authenticationPath={"/listing"}
          /> */}
          <Route
            path="/listing"
            element={<ProtectedRoute Component={ListingData} />}
          />
          {/* <Route path="/listing2" Component={ListingData} /> */}
          <Route
            path="/load-data"
            element={<ProtectedRoute Component={LoadData} />}
          />
          <Route
            path="/aerodrome"
            element={<ProtectedRoute Component={Aerodrome} />}
          />
          <Route
            path="/aerodrome-manage"
            element={<ProtectedRoute Component={AerodromeAdd} />}
          />
          <Route path="/user" element={<ProtectedRoute Component={Users} />} />
          <Route
            path="/user-manage"
            element={<ProtectedRoute Component={UserAdd} />}
          />
          <Route
            path="/app-const"
            element={<ProtectedRoute Component={AppConst} />}
          />
          <Route
            path="/app-const-manage"
            element={<ProtectedRoute Component={AppConstAdd} />}
          />
          <Route
            path="/gmail-setup"
            element={<ProtectedRoute Component={Index} />}
          />
          <Route path="/about-us" Component={AboutUs} />
          <Route path="/chat" Component={ChatHome} />
          <Route path="/chat-inner" Component={ChatInner} />
          <Route path="/login" Component={Login} />
          {/* <Route path='/annual' component={AnnualReport} /> 

        <Route path='/team' component={Teams} /> 
        <Route path='/blogs' component={Blogs} /> 
        <Route path='/sign-up' component={SignUp} />   */}
        </Routes>
      </Router>
      <AlertComponent />
    </AuthWrapper>
  );
}

export default App;
