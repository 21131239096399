import { SendSharp } from "react-ionicons";
import Navbar from "../../component/Navbar";
import SingleChat from "../../component/chat/SingleChat";
import Input from "../../component/items/Input";
import SingleChatLeft from "../../component/chat/SingleChatLeft";

export default function ChatInner() {
  return (
    <>
      <Navbar />
      <div className=" pt-20"></div>
      <div className="container flex">
        <div className=" w-4/12 h-screen border-2">
          <SingleChatLeft />
        </div>
        <div className="w-8/12 h-screen border-2">
          <SingleChat />
        </div>
      </div>
      <div className=" absolute bottom-0 w-full flex">
        <div className="w-10/12">
          <Input
            handleChange={undefined}
            value={undefined}
            labelText={""}
            labelFor={""}
            id={""}
            name={""}
            type={""}
            isRequired={false}
            placeholder={""}
            customClass={""}
          />
        </div>
        <div className=" w-2/12 justify-center items-center">
          <SendSharp />
        </div>
      </div>
    </>
  );
}
