// export const baseUrl = "http://localhost:8000/api/";
export const baseUrl = "/api/";
export const loginUrl = baseUrl + "login";
export const postData = baseUrl + "postData";
export const notamDelete = baseUrl + "notam-delete";
export const aerodromePostData = baseUrl + "aerodrome";
export const aerodromeDeleteData = baseUrl + "aerodrome-dele";
export const fetchMap = baseUrl + "get-map";
export const emails = baseUrl + "emails";
export const aerodromeUrl = baseUrl + "aerodrome";
export const appConstUrl = baseUrl + "app-const";
export const appConstPostData = appConstUrl + "/" + "add";
export const aerodromeSearchUrl = baseUrl + "aerodrome-search";
export const usersUrl = baseUrl + "user";
export const userPostData = usersUrl + "/" + "add";
export const userDeleteData = usersUrl + "/" + "delete";
export const gmailData = baseUrl + "/" + "gmail-data";

