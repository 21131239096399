import Navbar from "../../../component/Navbar";
import { AerodromeTable } from "../../../component/items/AerodromeTable";

export default function Aerodrome() {
  return (
    <>
      <Navbar />
      <div className="mt-20">
        <AerodromeTable />
      </div>
    </>
  );
}
