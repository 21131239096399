import {
  Card,
  Input,
  Button,
  Typography,
  Textarea,
} from "@material-tailwind/react";
import Navbar from "../../component/Navbar";
import { MouseEvent, useState } from "react";
import { dataPost } from "../../network";
import { postData } from "../../network/const";
import { Dialog } from "../../component/items/Dialog";
import { dialogDataTypes } from "../../component/items/itemDataType";

export function LoadData() {
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    btnText: "",
    description: "",
  });
  const [formState, setFormState] = useState({
    email: "notam@info.com",
    identification: "",
    data: "",
  });
  const handleChange = (e: any) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
    console.log(formState);
  };
  const initDialog = (data: dialogDataTypes) => {
    setOpen(true);
    setDialogData({
      title: data.title,
      btnText: data.btnText,
      description: data.description,
    });
  };

  const submitData = async () => {
    const data = await dataPost(postData, formState);
    console.log(data);

    if (data.status) {
      initDialog({
        description: data.message,
        title: data.title,
        btnText: "OK",
      });
      // setFormState({ email: "notam@info.com", identification: "", data: "" });
    } else {
      initDialog({
        description: data.message,
        title: data.title,
        btnText: "OK",
      });
    }
  };
  return (
    <>
      <Navbar />
      <div className="mt-20">
        <Card color="transparent" className=" items-center" shadow={false}>
          <Typography variant="h4" color="blue-gray">
            Load Data
          </Typography>
          <Typography color="gray" className="mt-1 font-normal">
            Please Fill the sample data
          </Typography>
          <form className="mt-8 mb-2 p-5  max-w-screen-lg w-full">
            <div className="mb-4 flex flex-col gap-6">
              <Input
                size="lg"
                label="Identification"
                crossOrigin={undefined}
                id="identification"
                onChange={handleChange}
                value={formState.identification}
              />
              <Input
                id="email"
                size="lg"
                label="Email"
                readOnly
                crossOrigin={undefined}
                value={formState.email}
              />
              <Textarea
                label="Data"
                rows={8}
                id="data"
                onChange={handleChange}
                value={formState.data}
              />
            </div>
            <Button className="mt-6" fullWidth onClick={submitData}>
              Submit Data
            </Button>
          </form>
        </Card>
        <Dialog
          open={open}
          handleOpen={() => setOpen(!open)}
          title={dialogData.title}
          description={dialogData.description}
          buttonText={dialogData.btnText}
        />
      </div>
    </>
  );
}
