import {
  Card,
  Input,
  Button,
  Typography,
  Textarea,
  Select,
  Option,
  Alert,
} from "@material-tailwind/react";
import Navbar from "../../../component/Navbar";
import { MouseEvent, useState } from "react";
import { dataPost } from "../../../network";
import { userPostData } from "../../../network/const";
import { Dialog } from "../../../component/items/Dialog";
import { dialogDataTypes } from "../../../component/items/itemDataType";
import { useLocation, useNavigate } from "react-router-dom";
import { UserModel } from "../../../network/dataType";

export default function UserAdd() {
  const navigate = useNavigate();
  const dataState: {
    state: UserModel | null;
  } = useLocation();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    btnText: "",
    description: "",
    status: false,
  });
  const [formState, setFormState] = useState({
    username: dataState.state?.username ?? "",
    email: dataState.state?.email ?? "",
    password: "",
  });
  const [errorState, setErrorState] = useState({
    username: "",
    email: "",
    password: "",
  });
  const handleChange = (e: any) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
    console.log(formState);
  };
  const initDialog = (data: dialogDataTypes) => {
    setOpen(true);
    setDialogData({
      title: data.title,
      btnText: data.btnText,
      description: data.description,
      status: data.status ?? false,
    });
  };

  const action = dataState.state?.id ? "Edit" : "Add";
  let isValid = true;
  const submitData = async () => {
    errorState.password = "";
    errorState.username = "";
    errorState.email = "";
    if (action == "Add" && formState.password?.length == 0) {
      errorState.password = "Please check password field";
      isValid = false;
    }
    if (formState.password.length) {
      if (formState.password.length < 6) {
        errorState.password = " Password must be at least 6 characters long";
      }
    }
    if (formState.username?.length == 0) {
      errorState.username = "Please check Name field";
      isValid = false;
    }
    if (formState.email?.length == 0) {
      errorState.email = "Please check email field";
      isValid = false;
    } else {
      if (!/\S+@\S+\.\S+/.test(formState.email ?? "")) {
        errorState.email = "Email address is invalid";
        isValid = false;
      }
    }
    if (!isValid) {
      console.log("is valid", isValid, errorState, formState);
      setErrorState({ ...errorState });
      return;
    }

    const url =
      userPostData + (dataState.state?.id ? `/${dataState.state.id}` : "");
    const data = await dataPost(url, formState);
    console.log(data);

    if (data.status) {
      initDialog({
        description: data.message,
        title: data.title,
        btnText: "OK",
        status: data.status,
      });
      // setFormState({ email: "notam@info.com", identification: "", data: "" });
    } else {
      initDialog({
        description: data.message,
        title: data.title,
        btnText: "OK",
      });
    }
  };
  return (
    <>
      <Navbar />
      <div className="mt-20">
        <Card color="transparent" className=" items-center" shadow={false}>
          <Typography variant="h4" color="blue-gray">
            User {action}
          </Typography>
          <form className="mt-8 mb-2 p-5  max-w-screen-lg w-full">
            <div className="mb-4 flex flex-col gap-4">
              <Input
                required
                error={errorState.username ? true : false}
                size="lg"
                label="Name"
                crossOrigin={undefined}
                id="username"
                onChange={handleChange}
                value={formState.username}
              />
              {errorState.username && (
                <div className=" text-red-700 relative">
                  *{errorState.username}
                </div>
              )}
              <Input
                required
                size="lg"
                label="Email"
                error={errorState.email ? true : false}
                crossOrigin={undefined}
                id="email"
                onChange={handleChange}
                value={formState.email}
              />
              {errorState.email && (
                <div className=" text-red-700 relative">
                  *{errorState.email}
                </div>
              )}
              <Input
                size="lg"
                label="Password"
                error={errorState.password ? true : false}
                crossOrigin={undefined}
                id="password"
                type="password"
                onChange={handleChange}
                value={formState.password}
              />

              {errorState.password && (
                <div className=" text-red-700 relative">
                  *{errorState.password}
                </div>
              )}
            </div>
            <div className="flex space-x-2 ">
              <Button className="mt-6" fullWidth onClick={() => navigate(-1)}>
                Cancel
              </Button>

              <Button
                className="mt-6"
                color="green"
                fullWidth
                onClick={submitData}
              >
                {action}
              </Button>
            </div>
          </form>
        </Card>

        <Dialog
          open={open}
          handleOpen={() => {
            setOpen(!open);
            if (dialogData.status) navigate(-1);
          }}
          title={dialogData.title}
          description={dialogData.description}
          buttonText={dialogData.btnText}
        />
      </div>
    </>
  );
}
