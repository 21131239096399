import {
  Card,
  Input,
  Button,
  Typography,
  Textarea,
  Select,
  Option,
} from "@material-tailwind/react";
import Navbar from "../../../component/Navbar";
import { MouseEvent, useEffect, useState } from "react";
import { dataPost } from "../../../network";
import {
  aerodromePostData,
  appConstPostData,
  postData,
} from "../../../network/const";
import { Dialog } from "../../../component/items/Dialog";
import { dialogDataTypes } from "../../../component/items/itemDataType";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { appConstModel } from "../../../network/dataType";
import { InputTags } from "../../../component/InputTags";
import { snakeCaseToWordCase, validateEmail } from "../../../include/Common";

export default function AppConstAdd() {
  const navigate = useNavigate();
  const dataState: {
    state: appConstModel;
  } = useLocation();
  const [open, setOpen] = useState(false);
  const [oneTag, setOneTag] = useState("");
  const [dialogData, setDialogData] = useState({
    title: "",
    btnText: "",
    description: "",
    status: false,
  });
  const [formState, setFormState] = useState({
    name: dataState.state?.name,
    id: dataState.state?.id,
    value: dataState.state?.value,
  });
  const handleChange = (e: any) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
    console.log(formState);
  };
  const initDialog = (data: dialogDataTypes) => {
    setOpen(true);
    setDialogData({
      title: data.title,
      btnText: data.btnText,
      description: data.description,
      status: data.status ?? false,
    });
  };
  useEffect(() => {
    console.log(!formState.name, "sss");
    if (!formState.name) {
      navigate(-1);
    }
  }, []);
  const submitData = async () => {
    const url =
      appConstPostData + (dataState.state?.id ? `/${dataState.state.id}` : "");
    const data = await dataPost(url, formState);
    console.log(data);

    if (data.status) {
      initDialog({
        description: data.message,
        title: data.title,
        btnText: "OK",
        status: data.status,
      });
      // setFormState({ email: "notam@info.com", identification: "", data: "" });
    } else {
      initDialog({
        description: data.message,
        title: data.title,
        btnText: "OK",
      });
    }
  };
  const action = dataState.state?.id ? "Edit" : "Add";
  if (!dataState.state?.id)
    return <Navigate to="/app-const" state={{}} replace={true} />;
  return (
    <>
      <Navbar />
      <div className="mt-20">
        <Card color="transparent" className=" items-center" shadow={false}>
          <Typography variant="h4" color="blue-gray">
            {snakeCaseToWordCase(formState.name ?? "")} {action}
          </Typography>
          <form className="mt-8 mb-2 p-5  max-w-screen-lg w-full">
            <div className="mb-4 flex flex-col gap-6">
              <div className="relative flex w-full ">
                <Input
                  size="lg"
                  label="Value"
                  crossOrigin={undefined}
                  id="value"
                  onChange={(e) => {
                    setOneTag(e.target.value);
                  }}
                  value={oneTag}
                />
                <Button
                  size="sm"
                  color={validateEmail(oneTag) ? "gray" : "blue-gray"}
                  disabled={!validateEmail(oneTag)}
                  className="!absolute right-1 top-1 rounded"
                  onClick={() => {
                    const arraydata = (
                      formState.value?.split(",") ?? []
                    ).filter((e) => e);
                    arraydata.push(oneTag);
                    setFormState((prev) => {
                      return { ...prev, value: arraydata.join(",") };
                    });
                    setOneTag("");
                  }}
                >
                  Add
                </Button>
              </div>
              <InputTags
                data={formState.value?.split(",").filter((e) => e) ?? []}
                removeFunction={(value: string) => {
                  console.log(value);

                  const arraydata =
                    formState.value
                      ?.split(",")
                      .filter((e) => e && e != value) ?? [];
                  setFormState((prev) => {
                    return { ...prev, value: arraydata?.join(",") };
                  });
                }}
              />
            </div>
            <div className="flex space-x-2 ">
              <Button className="mt-6" fullWidth onClick={() => navigate(-1)}>
                Cancel
              </Button>

              <Button
                className="mt-6"
                color="green"
                fullWidth
                onClick={submitData}
              >
                {action}
              </Button>
            </div>
          </form>
        </Card>

        <Dialog
          open={open}
          handleOpen={() => {
            setOpen(!open);
            if (dialogData.status) navigate(-1);
          }}
          title={dialogData.title}
          description={dialogData.description}
          buttonText={dialogData.btnText}
        />
      </div>
    </>
  );
}
