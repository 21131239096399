// @flow
import React, { useEffect, useState } from "react";
import Navbar from "../../../component/Navbar";
import { dataGet } from "../../../network";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { LogoGoogle } from "react-ionicons";
import { gmailData } from "../../../network/const";
import { AuthData } from "../../../context/AuthWrapper";
import { googleData } from "../../../network/dataType";
type Props = {};
export const Index = (props: Props) => {
  const authData = AuthData();
  const [gData, setGData] = useState<googleData>({});
  useEffect(() => {
    fetchData();

    // Simulate login success after 2 seconds
    // setTimeout(sendData, 2000);
    return () => {};
  }, []);

  const fetchData = async () => {
    const data = await dataGet(gmailData, {});
    if (data.status) {
      setGData(data.data);
    } else {
      authData?.setError({
        error: true,
        errorDescription: data.message,
        errorTitle: data.title,
        errorType: "error",
        hide: data.hide,
        code: data.code,
      });
    }
  };

  const openPopup = () => {
    if (gData?.loginUrl != null) {
      const newWindow = window.open(
        gData.loginUrl,
        "_blank",
        "width=500,height=500"
      );

      const receiveMessage = (event: any) => {
        console.log(event);

        if (event.origin === window.location.origin) {
          if (event.data === "login_success") {
            newWindow?.close();
            window.removeEventListener("message", receiveMessage);
          }
        }
      };
      if (newWindow) {
        window.addEventListener("message", receiveMessage, false);
        newWindow.focus();
      }
    } else {
      authData?.setError({
        error: true,
        errorDescription: "you cant login the gmail",
        errorTitle: "please contact admin",
        errorType: "error",
      });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="mt-20  ">
        <div className="flex justify-center items-center h-screen">
          <Card className=" mt-10 items-center w-2/3 bottom-1 shadow">
            <CardHeader className="w-full p-5 bg-blue-gray-50 text-sm font-bold">
              Google Connect
            </CardHeader>
            <CardBody>
              {gData.loginUrl && (
                <Button
                  className="flex items-center gap-3"
                  variant="outlined"
                  onClick={() => {
                    openPopup();
                  }}
                >
                  <LogoGoogle />
                  Connect Google
                </Button>
              )}
              {gData && (
                <List>
                  <ListItem>
                    <div>
                      <Typography variant="h6" color="blue-gray">
                        Status
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal"
                      >
                        <Button size="sm" className=" bg-green-300">
                          Online
                        </Button>
                      </Typography>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div>
                      <Typography variant="h6" color="blue-gray">
                        Email
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal"
                      >
                        {gData.me?.emailAddress}
                      </Typography>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div>
                      <Typography variant="h6" color="blue-gray">
                        Total Message
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal"
                      >
                        {gData.me?.messagesTotal}
                      </Typography>
                    </div>
                  </ListItem>
                </List>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
