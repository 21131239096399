import React, { useState } from "react";
type ReadMoreProps = {
  text: string;
  maxLength: number;
};
const ReadMore: React.FC<ReadMoreProps> = ({ text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleIsTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <>
      <div className="max-w-md mx-auto">
        {isTruncated ? (
          <div className="text-gray-700">
            {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
            <button
              onClick={toggleIsTruncated}
              className="text-blue-500 hover:underline focus:outline-none focus:underline"
            >
              Read more
            </button>
          </div>
        ) : (
          <div className="text-gray-700">
            {text}
            <button
              onClick={toggleIsTruncated}
              className="text-blue-500 hover:underline focus:outline-none focus:underline"
            >
              Read less
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ReadMore;
