import axios, { AxiosError, AxiosResponse, CancelToken } from "axios";
import { loginUrl } from "./const";
import { response } from "./dataType";
import { AuthData } from "../context/AuthWrapper";
import { getAccessTokenFromLocalStorage } from "../include/token";

export const dataPost = async (url: string, data: any): Promise<response> => {
  const jwtToken = getAccessTokenFromLocalStorage();
  try {
    const response: AxiosResponse<response> = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status == 401) {
        return new Promise<response>((resolve, reject) => {
          const jsonData: response = {
            status: false,
            title: axiosError.code ?? "Error",
            message: axiosError.message,
            data: [],
            code: 401,
          };
          resolve(jsonData);
        });
      }
      console.log(axiosError.response?.status);
    }
    return new Promise<response>((resolve, reject) => {
      const jsonData: response = {
        status: false,
        title: "Server Error",
        message: "Please check the server server is not responding ",
        data: [],
      };
      resolve(jsonData);
    });
  }
};
export const dataGet = async (
  url: string,
  data: any,
  source?: CancelToken
): Promise<response> => {
  const jwtToken = getAccessTokenFromLocalStorage();

  try {
    const response: AxiosResponse<response> = await axios.get(url, {
      params: data,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
      cancelToken: source,
    });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
      return new Promise<response>((resolve, reject) => {
        const jsonData: response = {
          status: false,
          title: "cancel",
          message: "cancel",
          data: [],
          hide: true,
        };
        resolve(jsonData);
      });
    }
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status == 401) {
        return new Promise<response>((resolve, reject) => {
          const jsonData: response = {
            status: false,
            title: axiosError.code ?? "Error",
            message: axiosError.message,
            data: [],
            code: 401,
          };
          resolve(jsonData);
        });
      }
      console.log(axiosError.response?.status);
    }
    // if (error?.response?.status == 401) {
    //   authdata?.logout();
    // }

    return new Promise<response>((resolve, reject) => {
      const jsonData: response = {
        status: false,
        title: "Server Error",
        message: "Please check the server server is not responding ",
        data: [],
      };
      resolve(jsonData);
    });
  }
};
