import L from "leaflet";
import pin_normal from "../../assets/pin-purple.png";
import pin_red from "../../assets/pin-red.png";
import pin_yellow from "../../assets/pin-yellow.png";
import pin_blue from "../../assets/pin-blue.png";

import "./../../App.css";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import Navbar from "../../component/Navbar";
import { IconButton, Input } from "@material-tailwind/react";
import { addDays, format, isToday } from "date-fns";

import {
  Circle,
  MapContainer,
  Marker,
  Polygon,
  Polyline,
  Popup,
  TileLayer,
  Tooltip
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ChangeEvent, useEffect, useState, useRef } from "react";
import { dataGet } from "../../network";
import { aerodromeSearchUrl, fetchMap } from "../../network/const";
import { AuthData } from "../../context/AuthWrapper";
import { AutocompleteData } from "../../network/dataType";
import { generateUniqueId, getNumberWithSuffix } from "../../include/Common";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import axios, { CancelToken } from "axios";
import MarkerClusterGroup from "../../ext/MarkerClusterGroup";
// import MarkerClusterGroup from "../../ext/react-leaflet-markercluster";
// delete L.Icon.Default.prototype._getIconUrl;

// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
// });

type mapsData = {
  LINE_STRING: any;
  QCODE_Q: string;
  NOTAM: string;
  Event_Time: string;
  Message: string;
  Lower_limit: string;
  Upper_limit: string;
  Plot_Point: any;
  Coordinates: any;
  Coordinate_Type: string;
  Center: any;
  Radius: number;
};
function Main() {
  const [data, setData] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [filterAerodrome, setFilteAerodrome] = useState("");
  const [aerodromeText, setAerodromText] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const [aerodromeValues, setAerodromeValues] = useState<AutocompleteData[]>(
    []
  );
  const [aerodromes, setAerodroms] = useState<AutocompleteData[]>([]);

  const [showNotams, setShowNotams] = useState<mapsData>()

  const [pinChange, setPinChange] = useState(pin_normal);

  const markerRef = useRef(null)

  const customIcon = new L.Icon({
    iconUrl: pinChange,
    iconRetinaUrl: pinChange,
    iconSize: [15, 15],
    popupAnchor: [-0, -0],
  });

  const authData = AuthData();
  const fetchMapApi = async (source: CancelToken) => {
    const datas = await dataGet(
      fetchMap,
      {
        aerodrome: aerodromeText,
        date: filterDate,
      },
      source
    );
    if (datas.status) {
      console.log(datas.data)
      setData(datas.data);
      console.log(datas.data)
    } else {
      // authData?.setErrorData({
      //   error: true,
      //   errorType: "error",
      //   errorDescription: datas.message,
      //   errorTitle: datas.title,
      // });
    }
  };

  const aerodromeSearch = async () => {
    const aerodromeData = await dataGet(aerodromeSearchUrl, {});
    if (aerodromeData.status) {
      setAerodromeValues(aerodromeData.data);
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    console.log("ch", inputValue);

    setAerodromText(inputValue);
    if (inputValue.length > 0) {
      const regex = new RegExp(`${inputValue}`, "i");
      const filteredSuggestions: AutocompleteData[] = aerodromeValues.filter(
        (option) => regex.test(option.value)
      );
      setAerodroms(filteredSuggestions);
    } else {
      setAerodroms([]);
    }
  };

  const [dates, setDates] = useState<string[][]>([]);

  useEffect(() => {
    aerodromeSearch();

    let today = new Date();

    setDates(
      Array.from({ length: 7 }, (_, i) => {
        const today = addDays(new Date(), i);
        console.log(i, today);
        return [
          isToday(today) ? "Today" : format(today, "do EEEE"),
          format(today, "yyyy-MM-dd"),
        ];
        // return arr;
      })
    );
  }, []);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const intervel = setInterval(() => {
      fetchMapApi(source.token);
    }, 45000);
    fetchMapApi(source.token);

    return () => {
      clearInterval(intervel);
      source.cancel("Component is unmounted");
    };
  }, [filterAerodrome, filterDate]);

  const checkIcons = (Message: string) => {
    let splitMessage = Message?.split(" ")
    let pin
    if (splitMessage[0]?.toLocaleLowerCase() === "fireworks") {
      pin = pin_red
    } else if (splitMessage[0]?.toLocaleLowerCase() === "usa" || splitMessage[0]?.toLocaleLowerCase() === "ua") {
      pin = pin_yellow
    } else if (splitMessage[0]?.toLocaleLowerCase() === "tra") {
      pin = pin_blue
    } else if (splitMessage[0]?.toLocaleLowerCase() === "obst") {
      pin = pin_normal
    } else {
      pin = pin_normal
    }

    return pin
  }


  return (
    <>
      <div className=" overflow-hidden h-full">
        <Navbar homePage={true} />
        <div className=" mt-24">{""}</div>
        <div className="flex flex-col relative w-full ">
          <div className=" absolute z-[500] right-0 px-8">
            {!showFilter && (
              <IconButton
                variant="filled"
                className="m-2"
                onClick={() => {
                  setShowFilter(true);
                }}
              >
                <AdjustmentsHorizontalIcon width={25} />
              </IconButton>
            )}
            {showFilter && (
              <div className="flex flex-col bg-gray-300 p-5">
                <Input
                  size="lg"
                  label="Aerodrome"
                  className="bg-white w-72"
                  crossOrigin={undefined}
                  id="aerodrome"
                  onChange={handleChange}
                  value={aerodromeText}
                  autoComplete="off"
                />
                {aerodromes.length != 0 && (
                  <ul className="bg-white border border-gray-300 rounded  mt-1 ">
                    {aerodromes.map((suggestion, index) => (
                      <li
                        key={index}
                        className="py-1 px-3 cursor-pointer hover:bg-gray-100"
                        onClick={() => {
                          setAerodromText(suggestion.name);
                          setAerodroms([]);
                          setFilteAerodrome(suggestion.value);
                        }}
                      >
                        {suggestion.name}
                      </li>
                    ))}
                  </ul>
                )}
                <select
                  value={filterDate}
                  onChange={(e) => {
                    setFilterDate(e.target.value);
                  }}
                  className="mt-2 w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 border text-sm px-3 py-3 rounded-md border-blue-gray-200 focus:border-gray-900 bg-white w-72"
                >
                  {dates?.map((e, index) => (
                    <option
                      key={generateUniqueId(index.toString())}
                      value={e[1].toString()}
                    >
                      {e[0].toString()}
                    </option>
                  ))}
                </select>
                <a
                  className=" mt-2 w-3 link cursor-pointer ml-auto underline text-cyan-700"
                  onClick={() => setShowFilter(false)}
                >
                  hide
                </a>
              </div>
            )}
          </div>
          <div className=" h-[100vh]">
            <MapContainer
              center={[24.4539, 54.3773]}
              zoom={9}
              scrollWheelZoom={true}
              zoomControl={false}
              style={{ height: "100vh", width: "100vw" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup>
                {data?.map((e: mapsData) => (
                  <>
                    <Marker
                      key={generateUniqueId(e.Message)}
                      position={e.Plot_Point}
                      ref={markerRef}
                      icon={new L.Icon({
                        iconUrl: checkIcons(e.Message),
                        iconRetinaUrl: checkIcons(e.Message),
                        iconSize: [20, 20],
                        popupAnchor: [-0, -0],
                      })}

                      eventHandlers={{
                        click: (c: any) => {
                          if (e.Coordinates) {
                            setShowNotams(e)
                          } else {
                            setShowNotams(undefined)
                          }
                        },

                      }}
                    >
                      <Popup key={generateUniqueId("pop")} >
                        <div>
                          <div className="w-auto h-auto ">
                            <p className="text-md border-b-2 pb-2 text-center font-semibold">
                              {e.NOTAM}
                            </p>
                            <p className=" text-md cursor-pointer  text-inherit ">
                              {e.QCODE_Q}
                            </p>
                            <p className="p-2 break-after-all text-[11px] bg-blue-gray-50 ">
                              {e.Message}
                            </p>
                            <p className=" text-xs w-full text-[ppx]  ">
                              {e.Event_Time}
                            </p>
                            <p className=" text-justify text-[10px] 	">
                              <span>{e.Lower_limit}</span>
                              <span>{e.Upper_limit}</span>
                            </p>
                          </div>
                        </div>
                      </Popup>
                    </Marker>
                    {showNotams == e &&
                      e.Coordinates ? (
                      <>
                        <Polygon
                          ref={markerRef}
                          pathOptions={{ color: "lime" }}
                          positions={e.Coordinates}
                        >
                        </Polygon>
                      </>
                    ) : <></>}
                    {/* {e.Coordinates ? (
                <Polygon
                  pathOptions={{ color: "lime" }}
                  positions={e.Coordinates}
                />
              ) : e.LINE_STRING ? (
                <Polyline
                  pathOptions={{ color: "red" }}
                  positions={e.LINE_STRING}
                />
              ) : e.Coordinate_Type &&
                e.Coordinate_Type.toLowerCase() == "circle" ? (
                <>
                  <Circle
                    center={e.Center}
                    pathOptions={{ color: "blue" }}
                    radius={200}
                  />
                </>
              ) : (
                <></>
              )} */}
                  </>
                ))}
              </MarkerClusterGroup>
            </MapContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
