import { EyeIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    ArrowDownTrayIcon,
    MagnifyingGlassIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    ArrowPathRoundedSquareIcon
} from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Select,
    Option,
    Popover,
    PopoverHandler,
    PopoverContent,
    Checkbox
} from "@material-tailwind/react";
import React, { FC, useEffect, useState } from "react";
import { dataGet, dataPost } from "../../network";
import { emails, notamDelete } from "../../network/const";
import { AuthData } from "../../context/AuthWrapper";
import ReadMore from "./ReadMore";
import { Dialog } from "./Dialog";
import { dateFormat, generateUniqueId } from "../../include/Common";
import axios, { CancelToken } from "axios";
import Datepicker, { DateRangeType } from "react-tailwindcss-datepicker";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import { MessageDialog } from "./MessageDialog";
import ExportToExcel from "../../include/ExportToExcel";
import LoadingComponent from "./LoadingComponent";

const TABLE_HEAD = [
    { name: "", class: " w-3 ", search: false },
    { name: "S No", class: " w-50 ", search: false },
    { name: "From", class: " w-50", search: true },
    { name: "Notam No", class: "", search: true },
    { name: "Aerodrome", class: "", search: true },
    { name: "Body", class: "w-72", search: true },
    { name: "Date", class: "", search: true },
    { name: "Starting Date", class: "", search: true },
    { name: "Ending Date", class: "", search: true },
    { name: "Status", class: "", search: true },
    { name: "Actions", class: "", search: false },
];

const TABS = [
    {
        label: "10",
        value: 10,
    },
    {
        label: "50",
        value: 50,
    },
    {
        label: "100",
        value: 100,
    },
    {
        label: "500",
        value: 500,
    },
    {
        label: "All",
        value: -1,
    },
];

type AERODROMEMODEL = {
    name: string;
    aerodrome: string;
};
type NOTAMMODEL = {
    id: string;
    messageId: string;
    QCODE_Q: string;
    Aerodrome_Code: string;
    Starting_Time_parse: string;
    Ending_Timeparse: string;
    Message: string;
    Lower_limit: string;
    Upper_limit: string;
    AERODROMEMODEL?: AERODROMEMODEL;
    NOTAM: string
};

type NOTAMNEVENTMODELs = {
    id: string;
    StartDate: string;
    EndDate: string;
    Daily: string;
    Btn: string;
    StartTime: string;
    EndTime: string;

};
type Email = {
    messageId: string;
    id: string;
    body: string;
    title: string;
    from: string;
    createdAt: string;
    NOTAMMODEL: NOTAMMODEL;
    NOTAMNEVENTMODELs: NOTAMNEVENTMODELs[];
};

type ViewProps = {
    data?: Email;
};

type TableProps = {
    TABLE_HEAD?: string[];
    Api?: string;
};

type SearchProps = {
    name: string,
    value: string;
}

const CreateView: React.FC<ViewProps> = ({ data }) => {
    type CreateTableProps = {
        title: string;
        tableData: {
            [key: string]: string;
        };
    };
    const CreateTable: FC<CreateTableProps> = ({ title, tableData }) => {
        return (
            <div className="w-full mx-auto px-1 py-1">
                <h2 className="text-xl font-bold mb-4 text-center">{title}</h2>
                <table className="w-full table-auto">
                    <tbody>
                        {Object.entries(tableData).map(([key, value]) => (
                            <tr key={generateUniqueId(key)}>
                                <th className="border px-1 py-1">{key}</th>
                                <td className="border px-1 py-1">{value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };
    if (data) {
        return (
            <>
                <CreateTable
                    title={data.title}
                    tableData={{
                        id: data?.messageId,
                        "Email From": data?.from,
                        "Email Date": dateFormat(data?.createdAt),
                        Title: data?.title,
                        Body: data?.body,
                        "Aerodrome Code": data.NOTAMMODEL.Aerodrome_Code,
                        QCODE: data.NOTAMMODEL.QCODE_Q,
                        Message: data.NOTAMMODEL.Message,
                        "Lower Limit": data.NOTAMMODEL.Lower_limit,
                        "End Date": data.NOTAMMODEL.Ending_Timeparse,
                        "Starting Date": data.NOTAMMODEL.Starting_Time_parse,
                        "Upper Limit": data.NOTAMMODEL.Upper_limit,
                    }}
                />
                <div className="flex">
                    {data.NOTAMNEVENTMODELs.map((e) => (
                        <Card className="sm:w-full  w-6/12">
                            <CreateTable
                                tableData={{
                                    "Start Date": dateFormat(e.StartDate, true),
                                    "Start Time": e.StartTime,
                                    "End Date": dateFormat(e.StartDate, true),
                                    "End Time": e.EndTime,
                                    Action: e.Btn ? "Between" : e.Daily ? "Daily" : "",
                                }}
                                title={"Events"}
                            />
                        </Card>
                    ))}
                </div>
            </>
        );
    }
    return <span key={generateUniqueId()}></span>;
};

export const Notamlist: React.FC<TableProps> = ({ }) => {
    const [page, setPage] = useState<number>(1);
    const [search, setSearch] = useState<SearchProps>({
        name: "",
        value: ""
    });
    const [dateRange, setdateRange] = useState<DateRangeType>({
        startDate: null,
        endDate: null,
    });
    const [perPage, setPerPage] = useState<any>(10);
    const [totalPages, setTotalPages] = useState(1);
    const [notamListCount, setNotamListCont] = useState(0);
    const [pageLinks, setPageLinks] = useState<any[]>([]);
    const [view, setView] = useState(false);
    const [viewDelete, setViewDelete] = useState(false);
    const [date, setDate] = React.useState<Date>();
    const [openPopover, setOpenPopover] = React.useState(false);
    const [loading, setLoading] = useState(false);


    const [selectedDeleteIDs, setSelectedDeleteIDs] = useState<any[]>([]);

    type dialog = {
        title: string;
        description: any;
        btnText: string;
    };
    const [dialogData, setDialogData] = useState<dialog>({
        description: "",
        title: "",
        btnText: "",
    });
    const [dialogDataDelete, setDialogDataDelete] = useState<
        dialog & { data: any }
    >({
        description: "",
        title: "",
        btnText: "",
        data: "",
    });
    const [TABLE_ROWS, setTABLEROWS] = useState<Email[]>([]);
    const authData = AuthData();
    // const TABLE_HEAD = ;
    const fetchData = async (source: CancelToken, searchItem: String) => {

        const emailData = await dataGet(
            emails,
            {
                page: page,
                perPage: perPage,
                search: searchItem,
                ...dateRange,
            },
            source
        );
        if (emailData.status) {
            if (emailData.data.rows) {
                setNotamListCont(emailData.data.count)
                setTABLEROWS(emailData.data.rows);
                setTotalPages(emailData.data.totalPages);
                pageLinksFun(emailData.data.totalPages);
            } else {
                setTotalPages(0);
            }
            setLoading(false)
        } else {
            authData?.setError({
                error: true,
                errorDescription: emailData.message,
                errorTitle: emailData.title,
                errorType: "error",
                hide: emailData.hide,
            });
            setLoading(true)
        }
    };
    const handleValueChange = (newValue: DateRangeType | null) => {
        console.log("newValue:", newValue);
        if (newValue) setdateRange(newValue);
    };

    const [refresh, setRefresh] = useState<string>("");

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        fetchData(source.token, search.value);

        return () => {
            source.cancel("Component is unmounted"); // Cancel the request when the component is unmounted
        };
    }, [page, perPage, dateRange, refresh]);

    const getStatus = (startDate: string | null, endDate: string | null) => {
        if (!startDate || !endDate) {
            return "N/A";
        }
        const stDate = new Date(startDate);
        const edDate = new Date(endDate);
        const currentDate = new Date();
        if (currentDate > stDate && currentDate < edDate) {
            return "Active";
        } else if (currentDate > edDate) {
            return "Completed";
        } else {
            return "Waiting";
        }
    };

    const pageLinksFun = (totalPage: number, showingLinks: number = 4) => {

        let i = 1;
        const limit: number = showingLinks / 2;
        const minLimit = page - limit <= 0 ? 1 : page - limit;
        const balance = minLimit < limit ? limit + minLimit : 0;
        let maxLimit =
            minLimit + limit + balance >= totalPage
                ? totalPage
                : minLimit + limit;
        console.log(minLimit, maxLimit, balance, limit, page);

        let pages = [];
        for (i = minLimit; i <= maxLimit; i++) pages.push(i);
        setPageLinks(pages);


    };

    const [alertDialogStatus, setAlertDialogSlatus] = useState(false);

    const [alertDialogData, setAlertDialogData] = useState({
        description: "",
        title: "",
        btnText: "",
    });
    const deleteFunction = async (row: string) => {
        console.log(row, selectedDeleteIDs)

        selectedDeleteIDs.map(async (item) => {
            const url = notamDelete + "/" + item.messageId;
            const data = await dataPost(url, {});
            if (data.status) {
                setRefresh(Math.random().toString());
                setAlertDialogSlatus(true);
                setAlertDialogData({
                    title: data.title,
                    description: data.message,
                    btnText: "Ok",
                });
            } else {
                setAlertDialogSlatus(true);
                setAlertDialogData({
                    title: data.title,
                    description: data.message,
                    btnText: "Ok",
                });
            }
        })



    };

    const handleSearchNames = (head: any, adons: any) => {
        setSearch({
            name: "",
            value: ""
        })
        if (head.name == "From") {
            return TABLE_ROWS.filter(name => name.from.toUpperCase().includes(search.value.toUpperCase()))
        } else if (head.name == "Notam No") {
            return TABLE_ROWS.filter(name => name?.NOTAMMODEL.NOTAM.includes(search.value))
        } else if (head.name == "Body") {
            return TABLE_ROWS.filter(name => name.body.includes(search.value))
        } else if (head.name == "Aerodrome") {
            return TABLE_ROWS.filter(name => (name.NOTAMMODEL?.AERODROMEMODEL?.name ? name.NOTAMMODEL?.AERODROMEMODEL?.name
                : name.NOTAMMODEL?.Aerodrome_Code).includes(search.value.toUpperCase()))
        } else if (head.name == "Starting Date") {
            const convertDate = new Date(date?.toISOString() || "");
            convertDate.setDate(convertDate.getDate() + 1);

            return TABLE_ROWS.filter(name => (dateFormat(name.NOTAMMODEL?.Starting_Time_parse)).includes(dateFormat(convertDate.toString())))
        } else if (head.name == "Ending Date") {

            const convertDate = new Date(date?.toISOString() || "");
            convertDate.setDate(convertDate.getDate() + 1);

            return TABLE_ROWS.filter(name => (dateFormat(name.NOTAMMODEL?.Ending_Timeparse)).includes(dateFormat(convertDate.toString())))
        }
        else if (head.name == "Date") {

            const convertDate = new Date(date?.toISOString() || "");
            convertDate.setDate(convertDate.getDate() + 1);

            return TABLE_ROWS.filter(name => (dateFormat(name.createdAt)).includes(dateFormat(convertDate.toString())))
        } else if (head.name == "Status") {
            return TABLE_ROWS.filter(name => (getStatus(
                name.NOTAMMODEL?.Starting_Time_parse,
                name.NOTAMMODEL?.Ending_Timeparse
            )).includes(adons))
        }
    }

    const handlePopupContent = (head: any) => {
        if (head.name == "Status") {
            return (
                <div className="flex justify-between">
                    <Button
                        className="cursor-pointer"
                        size="sm"
                        value="Active"
                        variant="text"
                        color="green"
                        onClick={() => {
                            let filterData = handleSearchNames(head, "Active")

                            setTABLEROWS(filterData || [])
                            // setTABLEROWS()
                        }}
                    >Active</Button>
                    <Button
                        className="cursor-pointer"
                        size="sm"
                        value="Completed"
                        variant="text"
                        color="amber"
                        onClick={() => {
                            let filterData = handleSearchNames(head, "Completed")

                            setTABLEROWS(filterData || [])
                            // setTABLEROWS()
                        }}
                    >Completed</Button>
                    <Button
                        className="cursor-pointer"
                        size="sm"
                        value="N/A"
                        variant="text"
                        color="gray"
                        onClick={() => {
                            let filterData = handleSearchNames(head, "N/A")

                            setTABLEROWS(filterData || [])
                            // setTABLEROWS()
                        }}
                    >N/A</Button>
                    <Button
                        className="cursor-pointer"
                        size="sm"
                        value="Waiting"
                        variant="text"
                        color="cyan"
                        onClick={() => {
                            let filterData = handleSearchNames(head, "Waiting")

                            setTABLEROWS(filterData || [])
                            // setTABLEROWS()
                        }}
                    >Waiting</Button>
                </div>
            )
        } else if (head.name == "Date" || head.name == "Starting Date" || head.name == "Ending Date") {
            return (
                <>
                    <DayPicker
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                        showOutsideDays

                        className="border-0"
                        classNames={{
                            caption: "flex justify-center py-2 mb-4 relative items-center",
                            caption_label: "text-sm font-medium text-gray-900",
                            nav: "flex items-center",
                            nav_button:
                                "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                            nav_button_previous: "absolute left-1.5",
                            nav_button_next: "absolute right-1.5",
                            table: "w-full border-collapse",
                            head_row: "flex font-medium text-gray-900",
                            head_cell: "m-0.5 w-9 font-normal text-sm",
                            row: "flex w-full mt-2",
                            cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                            day: "h-9 w-9 p-0 font-normal",
                            day_range_end: "day-range-end",
                            day_selected:
                                "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                            day_today: "rounded-md bg-gray-200 text-gray-900",
                            day_outside:
                                "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                            day_disabled: "text-gray-500 opacity-50",
                            day_hidden: "invisible",
                        }}
                        components={{
                            IconLeft: ({ ...props }) => (
                                <ChevronLeftIcon {...props} className="h-4 w-4 stroke-2" />
                            ),
                            IconRight: ({ ...props }) => (
                                <ChevronRightIcon {...props} className="h-4 w-4 stroke-2" />
                            ),
                        }}
                    />
                    <div className="flex gap-2">

                        <Input
                            size="lg"
                            crossOrigin={undefined}
                            value={date ? format(date, "PPP") : search.value}
                            placeholder={`${head.name} Search`}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                            onChange={(e) => setSearch({
                                name: "",
                                value: e.target.value
                            })}
                        />
                        <Button
                            variant="gradient"
                            className="flex-shrink-0"
                            onClick={() => {
                                let filterData = handleSearchNames(head, "")

                                setTABLEROWS(filterData || [])

                                setDate(undefined)
                                // setTABLEROWS()
                            }}>
                            Search
                        </Button>
                    </div>
                </>)
        } else {
            return <div className="flex gap-2">

                <Input
                    size="lg"
                    crossOrigin={undefined}
                    value={date ? format(date, "PPP") : search.value}
                    placeholder={`${head.name} Search`}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                    onChange={(e) => setSearch({
                        name: "",
                        value: e.target.value
                    })}
                />
                <Button
                    variant="gradient"
                    className="flex-shrink-0"
                    onClick={() => {
                        let filterData = handleSearchNames(head, "")

                        setTABLEROWS(filterData || [])
                        // setTABLEROWS()
                    }}>
                    Search
                </Button>
            </div>
        }
    }

    const handleExportData = () => {

        let tableArray: any = []
        TABLE_ROWS?.map((item, index) => {
            const aerodrome = item?.NOTAMMODEL?.AERODROMEMODEL?.name
                ? item?.NOTAMMODEL?.AERODROMEMODEL?.name
                : item?.NOTAMMODEL?.Aerodrome_Code;
            const startDate = dateFormat(item?.NOTAMMODEL?.Starting_Time_parse);
            const endDate = dateFormat(item?.NOTAMMODEL?.Ending_Timeparse);
            const status = getStatus(
                item?.NOTAMMODEL?.Starting_Time_parse,
                item?.NOTAMMODEL?.Ending_Timeparse
            );
            let data = {
                "S No": index + 1,
                "From": item.from,
                "Notam No": item.NOTAMMODEL.NOTAM,
                "Aerodrome": aerodrome,
                "Body": item.body,
                "Date": dateFormat(item.createdAt),
                "Starting Date": startDate,
                "Ending Date": endDate,
                "status": status
            }

            tableArray.push(data)
        })

        return tableArray

    }

    const toggleCheckbox = (row: any) => {

        if (selectedDeleteIDs.includes(row)) {
            setSelectedDeleteIDs(prevSelected => prevSelected.filter(item => item.messageId !== row.messageId));
        } else {
            setSelectedDeleteIDs(prevSelected => [...prevSelected, row]);
        }
    };

    console.log(selectedDeleteIDs)

    return (
        <>
            {/* {loading ? <LoadingComponent></LoadingComponent> : ""} */}
            <Card className="h-full w-full p-6" style={{ position: "relative" }}>
                <CardHeader floated={false} shadow={false} className="rounded-none ms-0">
                    <div className="mb-0 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                        <div>
                            <Typography variant="h2" color="blue-gray" >
                                Notam List
                            </Typography>
                        </div>
                        <div className="flex w-full shrink-0 gap-2 md:w-max">
                            <Button className="flex items-center gap-3" size="sm" onClick={() => {
                                const CancelToken = axios.CancelToken;
                                const source = CancelToken.source();
                                fetchData(source.token, "");
                            }}>
                                <ArrowPathRoundedSquareIcon strokeWidth={2} className="h-4 w-4" /> Reset
                            </Button>
                            <Button disabled={selectedDeleteIDs.length ? false : true} className="flex items-center gap-3" size="sm" onClick={() => {
                                setViewDelete(true);
                                setDialogDataDelete({
                                    title: "Delete",
                                    btnText: "Yes",
                                    description: `Do you want to delete selected Notams ?`,
                                    data: "",
                                });
                            }}>
                                <TrashIcon strokeWidth={2} className="h-4 w-4" /> Delete
                            </Button>
                            <ExportToExcel
                                data={handleExportData()}
                                fileName="Notam_list"
                                sheetName="Sheet1"
                            />
                        </div>
                    </div>
                </CardHeader>
                <CardBody className="p-0 overflow-scroll" style={{ height: "70vh" }}>
                    <table className="w-full min-w-max table-auto text-left break-all  whitespace-break-spaces">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head.name}
                                        className={`py-4 cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 ${head.class}`}
                                    >
                                        <div className="flex items-center justify-between notam-table-header px-2">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="px-2 flex items-center justify-between font-normal leading-none opacity-70 "
                                            >
                                                {head.name ?? ""}

                                            </Typography>
                                            {head.search ? <>
                                                <Popover placement="bottom">
                                                    <PopoverHandler>
                                                        <MagnifyingGlassIcon strokeWidth={2} className="h-4 w-4" ></MagnifyingGlassIcon>
                                                    </PopoverHandler>
                                                    <PopoverContent className="w-96" >
                                                        {handlePopupContent(head)}

                                                    </PopoverContent>
                                                </Popover>

                                            </> : ""}

                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {TABLE_ROWS.length ? (
                                TABLE_ROWS.map((row, index) => {
                                    console.log(row)
                                    const {
                                        id,
                                        from,
                                        createdAt,
                                        body,
                                        title,
                                        NOTAMMODEL,
                                        NOTAMNEVENTMODELs,
                                    } = row;
                                    const startDate = dateFormat(NOTAMMODEL?.Starting_Time_parse);
                                    const endDate = dateFormat(NOTAMMODEL?.Ending_Timeparse);
                                    const status = getStatus(
                                        NOTAMMODEL?.Starting_Time_parse,
                                        NOTAMMODEL?.Ending_Timeparse
                                    );
                                    const aerodrome = NOTAMMODEL?.AERODROMEMODEL?.name
                                        ? NOTAMMODEL?.AERODROMEMODEL?.name
                                        : NOTAMMODEL?.Aerodrome_Code;
                                    const isLast = index === TABLE_ROWS.length - 1;
                                    const classes = isLast
                                        ? "p-4"
                                        : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={id}>
                                            <td >
                                                <div className="flex items-center">
                                                    <Checkbox crossOrigin={true} onChange={(e) => toggleCheckbox(row)} />
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex items-center gap-3">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-bold"
                                                    >
                                                        {index + 1 ?? ""}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={`${classes} bg-blue-gray-50/50`}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {from ?? ""}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {NOTAMMODEL.NOTAM}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} bg-blue-gray-50/50`}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {aerodrome ?? ""}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <ReadMore text={body} maxLength={45} />
                                            </td>
                                            <td className={`${classes} bg-blue-gray-50/50`}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {dateFormat(createdAt)}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {startDate ?? ""}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} bg-blue-gray-50/50`}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {endDate ?? ""}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div className="w-max">
                                                    <Chip
                                                        size="sm"
                                                        variant="ghost"
                                                        value={status}
                                                        color={
                                                            status === "Active"
                                                                ? "green"
                                                                : status === "Completed"
                                                                    ? "amber"
                                                                    : "gray"
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td className={`${classes} bg-blue-gray-50/50`}>
                                                <Tooltip content="View Details">
                                                    <IconButton
                                                        variant="text"
                                                        onClick={() => {
                                                            setView(true);
                                                            setDialogData({
                                                                title: "View",
                                                                btnText: "Close",
                                                                description: <CreateView data={row} />,
                                                            });
                                                        }}
                                                    >
                                                        <EyeIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>

                                                {/* <Tooltip content="Delete">
                                                    <IconButton
                                                        variant="text"
                                                        onClick={() => {
                                                            setViewDelete(true);
                                                            setDialogDataDelete({
                                                                title: "Delete",
                                                                btnText: "Yes",
                                                                description: `Do you want to delete '${row.id}' ?`,
                                                                data: row.messageId,
                                                            });
                                                        }}
                                                    >
                                                        <TrashIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip> */}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td className=" text-center" colSpan={10}>
                                        Empty Data
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </CardBody>
                <CardFooter className="flex justify-end border-t border-blue-gray-50 py-4">
                    <div className="flex items-center justify-center">
                        <Typography variant="paragraph" className="me-2">
                            Rows per page
                        </Typography>
                        <div className="me-10">
                            <select
                                onChange={(e) => {
                                    setPage(1);
                                    setPerPage(e.target.value);
                                    setLoading(true)
                                }}>
                                {TABS.map(({ label, value }) => (
                                    <option key={value}
                                        value={value}>{label}</option>
                                ))}

                            </select>
                        </div>
                        <Typography variant="h6" className="">
                            {page * 10 - 9} to {(page * 10) <= notamListCount ? page * 10 : notamListCount} of {notamListCount}
                        </Typography>
                    </div>


                    <Button variant="text" size="sm" onClick={() => setPage((prev) => (prev == 1 ? 1 : prev - 1))}>
                        <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" ></ChevronLeftIcon>
                    </Button>
                    <div className="flex items-center gap-2">
                        {pageLinks.map((e) => {
                            if (e == "Dot") {
                                return (
                                    <Typography>
                                        .
                                    </Typography>
                                );
                            } else {
                                return (
                                    <IconButton
                                        key={generateUniqueId()}
                                        variant="text"
                                        size="sm"
                                        className={`${page == e ? "bg-blue-gray-100" : ""}`}
                                        onClick={() => setPage(e)}
                                    >
                                        {e}
                                    </IconButton>
                                );
                            }
                        })}
                    </div>
                    <Button variant="text" size="sm"
                        onClick={() =>
                            setPage((prev) => (prev == totalPages ? totalPages : prev + 1))
                        }>
                        <ChevronRightIcon strokeWidth={2} className="h-4 w-4" ></ChevronRightIcon>
                    </Button>
                </CardFooter>
                {loading ? <LoadingComponent></LoadingComponent> : ""}
            </Card>

            <Dialog
                open={view}
                handleOpen={() => setView(!view)}
                description={dialogData.description}
                title={dialogData.title}
                buttonText={dialogData.btnText}
                size="xl"
            />
            <MessageDialog
                open={viewDelete}
                handleClose={() => {
                    setViewDelete(!viewDelete);
                }}
                handleOpen={() => {
                    setViewDelete(!viewDelete);
                    deleteFunction(dialogDataDelete.data);
                }}
                description={dialogDataDelete.description}
                title={dialogDataDelete.title}
                succesBtn={dialogDataDelete.btnText}
                succesBtnClass={""}
                cancelBtn={"Cancel"}
                cancelBtnClass={"bg-red-200"}
                size="sm"
            />
            <Dialog
                open={alertDialogStatus}
                handleOpen={() => {
                    setAlertDialogSlatus(!alertDialogStatus);
                }}
                title={alertDialogData.title}
                description={alertDialogData.description}
                buttonText={"Ok"}
            />

        </>
    );
}