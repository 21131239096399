import React from "react";
import Input from "../items/Input";
import { LogoIonic, SendSharp } from "react-ionicons";

export default function SingleChatLeft() {
  return (
    <>
      <div className="flex flex-col   w-full">
        <div className="flex w-full  rounded-xl border-2 hover:bg-slate-400">
          <span className=" w-10 p-1 ">
            {<LogoIonic style={{ height: "100%", width: "100%" }} />}
          </span>
          <span className="w-full">Sajeerasdasd</span>
        </div>
        <div className="flex w-full rounded-xl mt-2  border-2  hover:bg-slate-400">
          <span className=" w-10 p-1">
            {<LogoIonic style={{ height: "100%", width: "100%" }} />}
          </span>
          <span className="w-full">Sajeerasdasd </span>
        </div>
        ß
      </div>
    </>
  );
}
