import { Link } from "react-router-dom";
import React from "react";
interface headerTypes {
  heading: any;
  paragraph: any;
  linkName: any;
  linkUrl: null | string;
}

export default function Header(header: headerTypes) {
  return (
    <div className="mb-10">
      <div className="flex justify-center">
        <img
          alt=""
          className="h-14 w-14"
          src="https://ik.imagekit.io/pibjyepn7p9/Lilac_Navy_Simple_Line_Business_Logo_CGktk8RHK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649962071315"
        />
      </div>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {header.heading}
      </h2>
      <div className="mt-2 text-center text-sm text-gray-600 mt-5">
        {header.paragraph}{" "}
        <Link
          to={header.linkUrl ?? "#"}
          className="font-medium text-purple-600 hover:text-purple-500"
        >
          {header.linkName}
        </Link>
      </div>
    </div>
  );
}
